import React, { useState } from 'react';

import { redirect } from '@Lib/global';
import Alert from '@UI/alert';
import Button from '@UI/button';
import CheckboxWithLabel from '@UI/forms/checkbox-with-label/checkbox-with-label';
import { Stack } from '@UI/stack/stack';

import { activateUser } from '../../services/management-service';
import LoginLayout from '../login-layout';

import styles from './user-activation.scss';

export const UserActivationPage = () => {
  const [fullname, setFullname] = useState<string>('');
  const [termsAgree, setTermsAgree] = useState(false);
  const [error, setError] = useState<string>('');
  const canActivate = fullname && termsAgree;

  return (
    <LoginLayout
      title="Create a Geckoboard account"
      heading="Create a Geckoboard account"
      footer={
        <div className={styles.footerContent}>
          <a
            href="https://geckoboard.com/legal/"
            target="_blank"
            rel="noreferrer"
            className={styles.termsLink}
          >
            Privacy Policy
          </a>
          <Stack.Spacer padding={2} />
          <a
            href="http://support.geckoboard.com/"
            target="_blank"
            rel="noreferrer"
            className={styles.termsLink}
          >
            Contact us
          </a>
        </div>
      }
    >
      <form
        onSubmit={async (e) => {
          e.preventDefault();

          try {
            await activateUser(fullname);
            redirect('/');
          } catch {
            setError(
              'Something went wrong. Please try again or contact support',
            );
          }
        }}
      >
        <div className={styles.formField}>
          <input
            type="text"
            placeholder="Full name"
            aria-label="Full name"
            name="fullname"
            value={fullname}
            className={styles.nameInput}
            onChange={(e) => {
              setFullname(e.currentTarget.value);
            }}
            autoFocus
          />
        </div>
        <div className={styles.formField}>
          <CheckboxWithLabel
            name="terms-agree"
            checked={termsAgree}
            onChange={(e) => {
              setTermsAgree(e.currentTarget.checked);
            }}
            label={
              <span>
                I have read and agree to the{' '}
                <a
                  href="https://geckoboard.com/legal/"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.termsLink}
                >
                  terms of service
                </a>
              </span>
            }
          />
        </div>
        <Stack.Col>
          {error && (
            <div>
              <Alert message={error} type="error" />
            </div>
          )}
          <Button stretched type={Button.TYPES.SUBMIT} disabled={!canActivate}>
            Create account
          </Button>
        </Stack.Col>
      </form>
    </LoginLayout>
  );
};

import PropTypes from 'prop-types';
import React from 'react';

import styles from './checkbox-with-label-styles.scss';

type Props = {
  label: React.ReactNode | string;
  value?: string | number;
  name?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
};

const CheckboxWithLabel = React.forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    const { name, checked, label, disabled, onChange, value } = props;

    return (
      <label className={styles.label}>
        <input
          ref={ref}
          name={name}
          type="checkbox"
          disabled={disabled}
          value={value}
          checked={checked}
          onChange={(e) => onChange && onChange(e)}
        />
        {label}
      </label>
    );
  },
);

// React.forwardRef wipes the component display
// name so we need to manually set it.
CheckboxWithLabel.displayName = 'CheckboxWithLabel';

CheckboxWithLabel.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
};

export default CheckboxWithLabel;

import classNames from 'classnames';
import React, { type ReactNode } from 'react';

import styles from './stack.scss';

type Padding = true | 0 | 2 | 3 | 4 | 8;

export type StackProps = {
  /**
   * Control the amount of padding around the edges
   * Can be passed as name-only for the default amount, or a multiplier
   */
  padding?: Padding;
  /**
   * Override the left/right padding when it needs to be differect
   * from top-bottom padding.
   */
  paddingX?: Padding;
  /**
   * Control the amount gap between elements in the stack
   * Can be passed as name-only for the default amount, or a multiplier
   */
  gap?: true | 2 | 4;
  children?: ReactNode;
  grow?: boolean;
  centered?: boolean;
  wrap?: boolean;
};

/**
 * Lay out elements in a row with consistent spacing around/between
 */
function Row(props: StackProps) {
  return stack(styles.row, props);
}

/**
 * Lay out elements in a column with consistent spacing around/between
 */
function Col(props: StackProps) {
  return stack(styles.col, props);
}

/**
 * Add space between elements when the default gap of a Stack isn't sufficient
 * Padding is effectively halfed to ensure you don't get double padding space
 */
function Spacer({ padding = 0 }: Pick<StackProps, 'padding'>) {
  return (
    <div
      className={classNames(styles.spacer, {
        [styles.p1]: padding === true,
        [styles.p2]: padding === 2,
        [styles.p3]: padding === 3,
        [styles.p4]: padding === 4,
        [styles.p8]: padding === 8,
      })}
    />
  );
}

/**
 * Fill up any available space by flex-growing to the container
 */
function Fill() {
  return <div className={styles.fill} />;
}

function stack(classname: string, props: StackProps) {
  const {
    padding = 0,
    paddingX,
    gap = true,
    grow = true,
    wrap = false,
    centered,
    children,
  } = props;

  return (
    <div
      className={classNames(styles.stack, classname, {
        [styles.grow]: grow,
        [styles.center]: centered,
        [styles.wrap]: wrap,
        [styles.p1]: padding === true,
        [styles.p2]: padding === 2,
        [styles.p3]: padding === 3,
        [styles.p4]: padding === 4,
        [styles.p8]: padding === 8,
        [styles.p1x]: paddingX === true,
        [styles.p0x]: paddingX === 0,
        [styles.p2x]: paddingX === 2,
        [styles.p3x]: paddingX === 3,
        [styles.p4x]: paddingX === 4,
        [styles.p8x]: paddingX === 8,
        [styles.g1]: gap === true,
        [styles.g2]: gap === 2,
        [styles.g4]: gap === 4,
      })}
    >
      {children}
    </div>
  );
}

export const Stack = { Row, Col, Spacer, Fill };

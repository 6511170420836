import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import Icon from '../icon';

import styles from './alert.scss';

export type AlertType = 'error' | 'info' | 'success' | 'warning';
export type DensityType = 'dense' | 'expanded';
export type ThemeType = 'default' | 'inherit';

const TYPES: Record<string, AlertType> = {
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
};

const mapTypeToIcon = {
  [TYPES.ERROR]: 'times',
  [TYPES.INFO]: 'info',
  [TYPES.SUCCESS]: 'check',
  [TYPES.WARNING]: 'exclamation',
};

const DENSITIES: Record<string, DensityType> = {
  DENSE: 'dense',
  EXPANDED: 'expanded',
};

const THEMES: Record<string, ThemeType> = {
  DEFAULT: 'default',
  INHERIT: 'inherit',
};

type AlertProps = {
  message: React.ReactNode;
  // scroll to the alert when it mounts
  scrollTo?: boolean;
  type?: AlertType;
  density?: DensityType;
  title?: React.ReactNode;
  theme?: ThemeType;
};

function Alert({
  message,
  title,
  scrollTo,
  type = TYPES.INFO,
  density = DENSITIES.EXPANDED,
  theme = THEMES.DEFAULT,
}: AlertProps) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollTo && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollTo]);

  return (
    <div
      ref={ref}
      role={type === TYPES.ERROR ? 'alert' : 'status'}
      className={`${styles.alert} ${styles[type]} ${styles[density]} ${styles[theme]}`}
    >
      <div className={styles.alertIcon}>
        <Icon name={mapTypeToIcon[type]} />
      </div>
      <div className={styles.alertContent}>
        {title && <span className={styles.alertTitle}>{title}</span>}
        <span className={styles.alertMessage}>{message}</span>
      </div>
    </div>
  );
}

Alert.TYPES = TYPES as Record<string, AlertType>;
Alert.DENSITIES = DENSITIES as Record<string, DensityType>;
Alert.THEMES = THEMES as Record<string, ThemeType>;

Alert.propTypes = {
  message: PropTypes.node.isRequired,
  density: PropTypes.oneOf([DENSITIES.DENSE, DENSITIES.EXPANDED]),
  scrollTo: PropTypes.bool,
  theme: PropTypes.oneOf([THEMES.DEFAULT, THEMES.INHERIT]),
  title: PropTypes.node,
  type: PropTypes.oneOf([
    TYPES.ERROR,
    TYPES.INFO,
    TYPES.SUCCESS,
    TYPES.WARNING,
  ]),
};

export default Alert;
